'use strict'
import axios from 'axios'
import util from './util'
class Drawer {
  constructor () {
    this.setCSRFToken = () => { util.setCSRFToken() }
    this.$html = document.documentElement
    this.$drawerPanelOpen = false
    this.$drawerPanel = document.getElementById('drawer')
    this.$drawerPanelContainer = document.querySelector('.drawer-container')
    this.$contentContainer = this.$drawerPanel.querySelector('#content-container')
    this.$leftDrawer = document.getElementById('#left-drawer')

    this.events = util.overlayEventsHandler()

    this.watchDrawer = new Event('custom:drawerspy')
  }

  init () {
    this.isMobileDevice()
  }

  open (content = null, animation = 'skeleton') {
    const overlays = document.querySelectorAll('.drawer-overlay')
    const newestOverlay = Array.from(overlays).at(-1)
    const newestContainer = newestOverlay.children[0]

    if (overlays.length <= 1 && !this.$drawerPanelOpen) {
      newestOverlay.classList.remove('hidden')
      this.$drawerPanelOpen = true

      this.populate(content || this.setAnimation(animation))

      setTimeout(() => {
        newestContainer.style.transform = 'translateX(0%)'
      }, 0)
    } else {
      this.buildNewOverlay(newestOverlay, content)
    }

    this.$drawerPanel.dispatchEvent(this.events.opened)
    this.drawerClassHandler()
  }

  setAnimation (animation) {
    return (animation === 'skeleton') ? this.__getSkeletonMarkup() : this.__getLoadingMarkup()
  }

  populate (content = this.__getSkeletonMarkup(), emptyDrawer = false) {
    const overlays = document.querySelectorAll('.drawer-overlay')
    const newestOverlay = Array.from(overlays).at(-1)
    const newestContainer = newestOverlay.children[0]
    const newContentContainer = newestContainer.querySelector('#content-container')
    const container = overlays.length < 2 ? this.$contentContainer : newContentContainer

    container.innerHTML = content

    this.$drawerPanel.dispatchEvent(this.events.populated)

    if (!emptyDrawer) {
      this.drawerClassHandler()
    }

    if (content === this.__getSkeletonMarkup()) {
      return
    }

    this.$contentContainer.dispatchEvent(this.watchDrawer)
  }

  buildNewOverlay (originalOverlay, content = null) {
    const overlayCount = document.querySelectorAll('.drawer-overlay').length
    const originalContainer = originalOverlay.children[0]
    const overlay = originalOverlay.cloneNode(true)
    const container = overlay.children[0]
    const contentContainer = container.querySelector('#content-container')

    this.resetContainerStyles(container)

    this.removeOriginalOverlay(originalOverlay)

    this.moveLabels('addition')

    contentContainer.innerHTML = content || this.__getSkeletonMarkup()

    document.body.appendChild(overlay)

    setTimeout(() => {
      container.style.transform = 'translateX(0%)'

      if (overlayCount < 4) {
        const toPercentage = ((originalContainer.offsetWidth * 100) / window.innerWidth) - 3 + '%'

        container.style.width = toPercentage
      }
    }, 10)
  }

  resetContainerStyles (container) {
    container.style.transform = 'translateX(100%)'
  }

  removeOriginalOverlay (overlay) {
    overlay.classList.add('drawer-overlay-hidden')
  }

  moveLabels (direction) {
    const containers = document.querySelectorAll('.drawer-container')
    const totalContainers = containers.length

    if (totalContainers >= 1) {
      Array.from(containers).forEach(($container) => {
        const label = $container.querySelector('.drawer-labels')

        if (direction === 'addition') {
          label.style.top = `${label.offsetTop + 45}px`
        } else {
          label.style.top = `${label.offsetTop - 45}px`
        }
      })
    }
  }

  appendBody (e) {
    const form = e.closest('form')

    document.body.appendChild(form)
    form.classList.add('hidden')
  }

  setWidth (value = '87.5%') {
    this.$drawerPanelContainer.style.width = value
  }

  close () {
    // Clear out the contents of the side panel
    this.populate(null, true)

    const overlays = document.querySelectorAll('.drawer-overlay')
    const overlay = Array.from(overlays).at(-1)
    const container = overlay.children[0]

    if (overlays.length > 1) {
      const newOverlay = Array.from(overlays).at(-2)
      container.style.transform = 'translateX(120%)'

      this.moveLabels('remove')

      setTimeout(() => {
        newOverlay.classList.remove('drawer-overlay-hidden')
        overlay.remove()
        container.remove()
      }, 300)
    } else {
      container.style.transform = 'translateX(120%)'

      this.$drawerPanelOpen = false

      setTimeout(() => {
        overlay.classList.add('hidden')
        this.$html.classList.remove('drawer-open')
        this.$html.classList.add('drawer-closed')
      }, 300)
    }
  }

  isMobileDevice () {
    window.addEventListener('resize', () => {
      const isMobileWidth = window.innerWidth < 640
      this.$drawerPanelContainer.classList.toggle('set-full-width', isMobileWidth)
    })
  }

  closeReviewSession (element) {
    const drawer = element.closest('#drawer')
    const hiddenLockInputs = drawer.querySelectorAll('[data-lock-input="true"]')

    if (hiddenLockInputs.length <= 0) {
      this.close()

      return
    }

    const identityID = document.querySelector('[data-identity-id')?.id

    hiddenLockInputs.forEach((hiddenLockInput) => {
      if (!hiddenLockInput?.value) {
        this.close()

        return
      }

      this.setCSRFToken()

      // find sibling input with id submission_id
      const submissionID = hiddenLockInput.closest('form').querySelector('#submission_id').value

      axios.delete(`/submissions/${submissionID}/locks/${hiddenLockInput.value}?identity_id=${identityID}`)
        .then(() => { this.close() })
        .catch(() => { this.close() })
    })
  }

  scrollTo (element, type, specific = null) {
    if (type === 'bottom') {
      element.scrollTop = element.scrollHeight
    }

    if (type === 'element') {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }

    if (type === 'specific') {
      const el = element.querySelector(`${specific}`)

      el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }
  }

  drawerClassHandler () {
    setTimeout(() => {
      this.$html.classList.add('drawer-open')
      this.$html.classList.remove('drawer-closed')
    }, 1000)
  }

  blankStatePositioning (el) {
    const fileDrag = el.querySelector('.file-drag')
    const attachment = el.querySelector('.file-container')
    const classes = ['h-full', 'flex', 'items-center', 'justify-center']

    if (!fileDrag && !attachment) {
      el.classList.add(...classes)
    }
  }

  __getLoadingMarkup () {
    return `
      <div class="h-screen flex items-center justify-center">
        <svg
          width="64px" height="64px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            class="stroke-current text-whitelabel-500"
            cx="50" cy="50" r="32" fill="none"
            stroke-dasharray="150 50" stroke-width="2"
          >
            <animateTransform
              attributeName="transform" type="rotate" repeatCount="indefinite"
              dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
          </circle>
        </svg>
      </div>
    `
  }

  __skeletonParagraphs () {
    return `
      <div class="space-y-8" id="pre-paragraph">
        <div class="skeleton" id="pre-title"></div>
        <div class="skeleton" id="pre-text"></div>
        <div class="skeleton" id="pre-text"></div>
        <div class="skeleton" id="pre-text"></div>
      </div>
    `
  }

  __skeletonAttachments (id) {
    return `
      <div class="flex flex-col" id=${id}>
        <h1 class="bg-gray-100 py-4 pl-4">&nbsp;</h1>
        <div class="space-y-8 mt-12 px-8" id="pre-paragraph">
          <div class="skeleton" id="pre-title"></div>
          <div class="skeleton" id="pre-text"></div>
          <div class="skeleton" id="pre-attachment"></div>
        </div>
      </div>
    `
  }

  __getSkeletonMarkup () {
    return `
      <div id="skeleton-container">
        <div class="flex items-center" id="skeleton-header">
          <div class="skeleton"></div>
        </div>
        <div class="overflow-y-scroll space-y-20 pt-16 px-8" id="skeleton-left-drawer">
          ${this.__skeletonParagraphs().repeat(3)}
        </div>
        <div class="flex flex-col" id="skeleton-right-drawer">
          ${this.__skeletonAttachments('i-one')}
          ${this.__skeletonAttachments('i-two')}
        </div>
        <div class="flex items-center" id="skeleton-footer">
          <div class="skeleton"></div>
        </div>
      </div>
    `
  }
}

export default Drawer
