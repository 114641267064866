import axios from 'axios'

class Locking {
  constructor () {
    this.setCSRFToken = () => { window.app.util.setCSRFToken() }
  }

  init () {
    window.addEventListener('focus', this.handleChange.bind(this))
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))
  }

  handleChange (event) {
    if (document.visibilityState === 'hidden') {
      this.freezeReview()
    } else {
      this.unfreezeReview()
    }
  }

  handleBeforeUnload (event) {
    var element = event.srcElement.activeElement

    if (element.dataset.closeReview === 'true' || element.dataset.confirm || element.dataset.attachment) { return }

    this.closeLocking()
  }

  closeLocking () {
    const lockInputs = document.querySelectorAll('[data-lock-input="true"]')
    const identityID = document.querySelector('[data-identity-id')?.id

    if (lockInputs.length === 0 || !identityID) { return }

    lockInputs.forEach(lockInput => {
      var lockID = lockInput.value

      if (!lockID || lockID === null) { return }

      this.setCSRFToken()

      const submissionID = lockInput.closest('form').querySelector('#submission_id').value

      return axios.delete(`/submissions/${submissionID}/locks/${lockID}?identity_id=${identityID}`)
    })
  }

  freezeReview () {
    var sessionId = this.getReviewSessionId()
    if (!sessionId || sessionId === null) { return }

    this.setCSRFToken()

    this.updateReviewSession(sessionId)
      .then(response => response)
      .catch(error => error)
  }

  unfreezeReview () {
    var sessionId = this.getReviewSessionId()
    if (!sessionId || sessionId === null) { return }

    this.setCSRFToken()

    this.updateReviewSession(sessionId)
      .then(response => {
        if (response.data === null) { this.showStaleDialog() }
      })
      .catch(error => error)
  }

  getReviewSessionId () {
    var submissionField = document.querySelectorAll('[id=review_session_id]')[0]

    if (!submissionField) { return null }

    return submissionField.value
  }

  showStaleDialog () {
    document.querySelector('#dialog').showModal()

    const button = document.querySelector('.dialog-accept')
    button.addEventListener('click', this.handleConfirmation.bind(this))
  }

  handleConfirmation () {
    window.location.href = '/submissions/all'
  }

  async updateReviewSession (sessionId) {
    try {
      return await axios.put(`/api/review_sessions/${sessionId}`)
    } catch (error) {
      return error
    }
  }
}

export default Locking
