import { Controller } from '@hotwired/stimulus'
import { stimulus } from '../application'
import BulkEditController from './bulk-edit'

type RelationData = Record<string, number>

export default class BulkEditCheckboxesController extends Controller<HTMLElement> {
  declare bulkEditController: BulkEditController
  declare relationData: RelationData
  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly hiddenInputTargets: HTMLInputElement[]
  declare readonly summaryIndicatorTargets: HTMLElement[]
  declare entityValue: string
  declare relationValue: string

  static targets = [
    'checkbox',         // The checkbox for a given Subtopic
    'hiddenInput',      // A hidden <input> that tells the backend which
                        // Subtopics the user is adding to or removing from
                        // the bulk-edited Resource Items
    'summaryIndicator', // A little dot that appears next to the Topic names
                        // to indicate that the user has modified Subtopic
                        // checkboxes within
  ]

  static values = {
    entity: String,
    relation: String,
  }

  connect() {
    this.#cleanCollectionCheckBoxesCruftUp()

    // HACK: This is a workaround for a flaw in Stimulus. Ideally, we would
    // connect to the bulk-edit controller as an outlet, but Stimulus was
    // having trouble finding the outlet when I tried that (I *think* because
    // the outlet is an ancestor of this controller's element).
    // This method works, but is not something we should have to do.
    this.bulkEditController = stimulus.getControllerForElementAndIdentifier(
      this.element.closest('table[data-controller="bulk-edit"]'),
      'bulk-edit'
    ) as BulkEditController

    this.bulkEditController.element.addEventListener(
      'bulk-edit:idsValue:changed',
      this.#handleBulkEditChange.bind(this)
    )
  }

  disconnect() {
    this.bulkEditController.element.removeEventListener(
      'bulk-edit:idsValue:changed',
      this.#handleBulkEditChange.bind(this)
    )
  }

  checkboxTargetConnected(element: HTMLInputElement) {
    element.addEventListener('input', this.#handleCheckboxChange.bind(this))
  }

  checkboxTargetDisconnected(element: HTMLInputElement) {
    element.removeEventListener('input', this.#handleCheckboxChange.bind(this))
  }

  // Private methods

  /**
   * We use Rails' `collection_check_boxes` helper to render the Subtopic
   * checkboxes, but it adds a bunch of empty inputs that aren't needed. Rails
   * assumes that we're interfacing with record attributes, but in this case
   * we want the checkboxes to not have names at all. Instead, we'll use the
   * state of these nameless checkboxes to generate hidden inputs, and those
   * hidden inputs will be submitted to the backend.
   */
  #cleanCollectionCheckBoxesCruftUp() {
    const emptyInputs = this.element.querySelectorAll<HTMLInputElement>(
      'input[name="[]"]'
    )

    emptyInputs.forEach(input => input.remove())
  }

  /** set the input name by data type values and an action (add or remove) */
  #hiddenInputName(action: string) {
    return `${this.entityValue}[${this.relationValue}_${action}][]`
  }

  /** Collapse all `<details>` elements */
  #closeDetails() {
    this.element.querySelectorAll('details').forEach(details => {
      details.removeAttribute('open')
    })
  }

  /** Utility method to create a `hiddenInput` target with a given name/value */
  #createHiddenInput(name: string, value: string) {
    const input = document.createElement('input')
    input.dataset.bulkEditResourceItemSubtopicsTarget = 'hiddenInput'
    input.name = name
    input.type = 'hidden'
    input.value = value
    return input
  }

  /** Respond whenever the user checks or unchecks a Resource Item checkbox */
  #handleBulkEditChange() {
    this.#resetHiddenInputs()
    this.#setRelationData()
    this.#updateCheckboxes()
    this.#updateSummaries()
    this.#closeDetails()
  }

  /** Determine what to do when the user checks a Subtopic checkbox */
  #handleCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement
    const { checked, value: relationId } = checkbox
    const hiddenInput = this.hiddenInputTargets.find(input => input.value === relationId)
    const hiddenInputName = this.#hiddenInputName(checked ? "add" : "remove")
    const belongsToResourceItems = Object.keys(this.relationData).includes(relationId)

    // The presence of a hidden input indicates that the user has already
    // modified this Subtopic's checkbox. If the Subtopic is associated with
    // one or more of the Resource Items that the user is bulk-editing,
    // update the hidden input's name to reflect whether we're adding or
    // removing the association to the Subtopic.
    if (hiddenInput && belongsToResourceItems) {
      hiddenInput.name = hiddenInputName
    }

    // If a hidden input is present but the Subtopic is NOT associated with any
    // of the bulk-edited Resource Items, we can infer that it was previously
    // created as an "add" operation, but also that the user is trying to
    // uncheck/remove it now.
    else if (hiddenInput) {
      hiddenInput.remove()
    }

    // Otherwise, the user is applying a new Subtopic
    // and we need to create a new hidden input for it.
    else {
      this.element.append(this.#createHiddenInput(hiddenInputName, relationId))
    }

    this.#setCheckboxRemovalState(checkbox, belongsToResourceItems && !checked)
    this.#updateSummaries()
  }

  /**
   * Deletes all of this controller's hidden inputs. This method is called
   * whenever the user checks or unchecks a resource item checkbox.
   */
  #resetHiddenInputs() {
    this.hiddenInputTargets.forEach(input => input.remove())
  }

  /**
   *
   * @param checkbox The Subtopic checkbox to update
   * @param removed Set to `true` if the Subtopic is currently associated with
   * one or more of the selected resource items but the user has
   * unchecked the Subtopic's checkbox.
   */
  #setCheckboxRemovalState(checkbox: HTMLInputElement, removed: boolean) {
    checkbox.dataset.removed = String(removed)
    checkbox.parentElement.classList[removed ? 'add' : 'remove'](
      'line-through',
      'opacity-50'
    )
  }

  /**
   * Created a map of Subtopic IDs to the number of times they appear in the
   * selected resource items. This data is used to determine which checkboxes
   * are checked, indeterminate, marked as removed, or no-op'd.
   */
  #setRelationData() {
    const data: RelationData = {}

    this.bulkEditController.checkboxTargets.forEach(checkbox => {
      if (!checkbox.checked) return

      JSON.parse(checkbox.dataset.relationData)[this.relationValue].forEach((id: string) => {
        data[id]
          ? data[id] += 1 // Increment the count if the subtopic ID already exists
          : data[id] = 1  // Otherwise, set the count to 1
      })
    })

    this.relationData = data
  }

  /**
   * Update the state of the checkboxes based on the current state of the
   * selected resource items. This method is called whenever the user checks
   * or unchecks a resource item checkbox.
   */
  #updateCheckboxes() {
    const resourceItemCheckboxes = this.bulkEditController.checkboxTargets
    const checkedResourceItemCount = resourceItemCheckboxes.filter(checkbox => checkbox.checked).length

    this.checkboxTargets.forEach(checkbox => {
      const relationId = checkbox.value
      const checked = Object.keys(this.relationData).includes(relationId)
      const indeterminate = checked && this.relationData[relationId] < checkedResourceItemCount

      checkbox.checked = checked
      checkbox.indeterminate = indeterminate

      this.#setCheckboxRemovalState(checkbox, false)
    })
  }

  /**
   * Show or hide the indicators that communicate which topics
   * have modified suptopic checkboxes within them
   */
  #updateSummaries() {
    this.summaryIndicatorTargets.forEach(summaryIndicator => {
      const summaryCheckboxes = summaryIndicator
        .closest('details')
        .querySelectorAll<HTMLInputElement>(
          'input[data-bulk-edit-checkboxes-target="checkbox"]'
        )

      let active = false

      for (const checkbox of summaryCheckboxes) {
        if (checkbox.checked || checkbox.dataset.removed === 'true') {
          active = true
          break
        }
      }

      summaryIndicator.classList[active ? 'remove' : 'add']('hidden')
    })
  }
}
