import { Controller } from '@hotwired/stimulus'
import { throttle } from 'throttle-debounce'

export default class FilterViewController extends Controller<HTMLElement> {
  static targets = [
    'accordion',          // Each filter exists within an accordion component
    'topbar',             // The top bar form that displays currently applied filters
    'sidebar',            // The sidebar contains a form
    'sidebarForm',        // The sidebar form is a collection of filter accordions
    'sidebarFormContent', // The content of the sidebar form (minus the submit button)
    'sidebarToggle',      // The button that toggles the sidebar
  ]

  declare contentWrapper: HTMLElement
  declare sidebarOpen: boolean
  declare readonly accordionTargets: HTMLDetailsElement[]
  declare readonly topbarTarget: HTMLFormElement
  declare readonly sidebarTarget: HTMLElement
  declare readonly sidebarFormContentTarget: HTMLElement
  declare readonly sidebarFormTarget: HTMLFormElement
  declare readonly sidebarToggleTarget: HTMLButtonElement

  SIDEBAR_WIDTH = '16rem'
  SIDEBAR_GAP = '1.5rem'
  SIDEBAR_OFFSET = [
    '-',
    (parseFloat(this.SIDEBAR_WIDTH) + parseFloat(this.SIDEBAR_GAP)),
    'rem'
  ].join('')

  connect() {
    this.contentWrapper = document.getElementById('content-wrapper')
    const sidebarOpenState = this.#getSidebarOpenState()

    this.#setSidebarOpenState(sidebarOpenState)
    this.#determineWhichAccordionsToOpen()
    this.#setSidebarToggleAttrs(sidebarOpenState)

    if (sidebarOpenState === false) this.#hideSidebar()

    window.addEventListener('resize', this.#setSidebarContentMaxHeight)
    this.contentWrapper.addEventListener('scroll', this.#setSidebarContentMaxHeight)

    // HACK: Wait for the next tick to trigger the method
    // Otherwise, the height will be calculated incorrectly
    setTimeout(() => this.#setSidebarContentMaxHeight(), 0)
  }

  disconnect() {
    window.removeEventListener('resize', this.#setSidebarContentMaxHeight)
    this.contentWrapper.removeEventListener('scroll', this.#setSidebarContentMaxHeight)
  }

  // Public methods

  toggleSidebar() {
    this.#setSidebarToggleAttrs(!this.sidebarOpen)

    this.sidebarTarget
      .animate(
        [
          { marginRight: '0rem', opacity: 1 },
          { marginRight: this.SIDEBAR_OFFSET, opacity: 0 },
        ],
        {
          direction: this.sidebarOpen ? 'normal' : 'reverse',
          duration: 100,
          easing: 'ease-out',
          fill: 'both',
        }
      )
      .finished
      .then(() => {
        this.sidebarTarget.classList.toggle('pointer-events-none')
        this.#setSidebarOpenState(!this.sidebarOpen)
      })
  }

  // 'Private' methods

  #determineWhichAccordionsToOpen() {
    if (this.#elemHasActiveFilters(this.sidebarTarget)) {
      this.#openPopulatedAccordions()
    } else {
      this.#openAllAccordions()
    }
  }

  #elemHasActiveFilters(element: HTMLElement) {
    const hasCheckedBoxes = Boolean(element.querySelector('input[type=checkbox]:checked'))
    const hasFilledSearches = Boolean(element.querySelector('input[type=search][value]'))

    return Boolean(hasCheckedBoxes || hasFilledSearches)
  }

  #getSidebarOpenState() {
    const storedValue = localStorage.getItem('filterViewSidebarOpen')

    return storedValue === null ? true : storedValue === 'true'
  }

  #hideSidebar() {
    this.sidebarTarget.style.marginRight = this.SIDEBAR_OFFSET
    this.sidebarTarget.style.opacity = '0'
    this.sidebarTarget.classList.add('pointer-events-none')
  }

  #openAllAccordions() {
    this.accordionTargets.forEach((accordion) => accordion.open = true)
  }

  #openPopulatedAccordions() {
    this.accordionTargets.forEach((accordion) => {
      if (this.#elemHasActiveFilters(accordion)) accordion.open = true
    })
  }

  #setSidebarContentMaxHeight = throttle(20, function() {
    if (!this.sidebarOpen) return

    const gutterHeight = 16 // Space between content and form's submit button, in pixels
    const viewportHeight = window.innerHeight
    const formHeight = this.sidebarFormTarget.getBoundingClientRect().height
    const formContentHeight = this.sidebarFormContentTarget.getBoundingClientRect().height
    const formContentTop = this.sidebarFormContentTarget.getBoundingClientRect().top

    // Get the height of the area that's beneath the form content
    const formSubmitHeight = formHeight - formContentHeight

    // Get the distance from the top of the content
    // wrapper to the top of the form content
    const formContentTopFromContentWrapper =
      formContentTop
        - this.contentWrapper.getBoundingClientRect().top
        - gutterHeight

    if (formContentTopFromContentWrapper < 1) return

    // Calculate the maximum height the form content can be
    const maxHeight = Math.floor(
      viewportHeight
        - formContentTop
        - formSubmitHeight
        - gutterHeight
    )

    // Get the form content's current max height value
    const currentMaxHeight = parseInt(
      this.sidebarFormContentTarget.style.maxHeight,
      10
    )

    if (maxHeight === currentMaxHeight) return

    this.sidebarFormContentTarget.style.maxHeight = `${maxHeight}px`
  }.bind(this))

  #setSidebarOpenState(value: boolean) {
    this.sidebarOpen = value
    localStorage.setItem('filterViewSidebarOpen', String(value))
  }

  #setSidebarToggleAttrs(value: boolean) {
    this.sidebarToggleTarget.setAttribute('aria-expanded', String(value))
  }
}
